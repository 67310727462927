import * as Sentry from "@sentry/node";
import { firebase } from "~/src/api";

const logSentryError = (err) => {
  const authUser = firebase.auth.currentUser;

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: authUser && authUser.uid,
      email: authUser && authUser.email,
    });
  });

  Sentry.captureException(err);
};

export default logSentryError;
