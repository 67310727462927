export const PROFILE_TEACHER = "/profile/teacher";
export const PROFILE_TEACHER_ADD = "/profile/teacher/add";
export const PROFILE_TEACHER_EDIT_ABOUT = "/profile/teacher/edit-about";
export const PROFILE_TEACHER_EDIT_PHOTOS = "/profile/teacher/edit-photos";
export const PROFILE_TEACHER_EDIT_SKILLS = "/profile/teacher/edit-skills";
export const PROFILE_TEACHER_EDIT_VIDEO = "/profile/teacher/edit-video";
export const PROFILE_TEACHER_EDIT_DESCRIPTION = "/profile/teacher/edit-description";
export const PROFILE_TEACHER_EDIT_EXPERIENCE = "/profile/teacher/edit-experience";
export const PROFILE_TEACHER_EDIT_QUALIFICATIONS = "/profile/teacher/edit-qualifications";
export const PROFILE_TEACHER_EDIT_LOCATION = "/profile/teacher/edit-location";
export const PROFILE_TEACHER_VIEW_AVAILABILITY = "/profile/teacher/edit-availability";
export const PROFILE_TEACHER_EDIT_AVAILABILITY = "/profile/teacher/edit-availability/:day";

export const PROFILE_STUDIO = "/profile/studio";
export const PROFILE_STUDIO_ADD = "/profile/studio/add";
export const PROFILE_STUDIO_EDIT_ABOUT = "/profile/studio/edit-about";
export const PROFILE_STUDIO_EDIT_STYLES = "/profile/studio/edit-styles";
export const PROFILE_STUDIO_EDIT_WEBSITE = "/profile/studio/edit-website";
export const PROFILE_STUDIO_EDIT_LOCATIONS = "/profile/studio/edit-locations";
export const PROFILE_STUDIO_EDIT_DESCRIPTION = "/profile/studio/edit-description";

export const SITE = "/site";
export const SITE_ADD = "/site/add";
export const SITE_EDIT_SEO = "/site/edit-seo";
export const SITE_EDIT_QUALIFICATIONS = "/site/edit-qualifications";
export const SITE_EDIT_SKILLS = "/site/edit-skills";
export const SITE_EDIT_PHOTOS = "/site/edit-photos";
export const SITE_EDIT_VIDEO = "/site/edit-video";

export const PROFILE_YOUR_JOBS = "/profile/your-jobs";

export const ACCOUNT_SETTINGS = "/account";

export const TEACHERS = "/teachers";
export const TEACHER = "/teachers/:username";

export const STUDIOS = "/studios";
export const STUDIO = "/studios/:username";

export const JOBS = "/jobs";
export const JOB = "/jobs/:id";
export const JOB_EDIT = "/jobs/:id/edit";
export const JOB_ADD = "/jobs/add";

export const MESSAGES = "/messages";
export const MESSAGE = "/messages/:id";
export const MESSAGE_ABOUT = "/messages/:id/about";

export const HOME = "/";
export const CONTACT = "mailto:hello@morgancarter.com.au";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const ABOUT = "/about";

export const BLOG = "/blog";
export const BLOG_POST = "/blog/:id";
export const BLOG_WEBSITES = "/blog/bolstr-websites-launch";

export const SIGN_UP = "/sign-up";
export const LOG_IN = "/log-in";
export const LOG_OUT = "/log-out";
export const FORGOT_PASSWORD = "/forgot-password";

export const ONBOARDING = "/onboarding";

export const ONBOARDING_TEACHER_CREATE = "/onboarding/teacher/create";
export const ONBOARDING_TEACHER_SKILLS = "/onboarding/teacher/skills";
export const ONBOARDING_TEACHER_LOCATION = "/onboarding/teacher/location";
export const ONBOARDING_TEACHER_EXPERIENCE = "/onboarding/teacher/experience";
export const ONBOARDING_TEACHER_QUALIFICATIONS = "/onboarding/teacher/qualifications";
export const ONBOARDING_TEACHER_DESCRIPTION = "/onboarding/teacher/description";
export const ONBOARDING_TEACHER_PHOTOS = "/onboarding/teacher/photos";
export const ONBOARDING_TEACHER_VIDEO = "/onboarding/teacher/video";

export const ONBOARDING_STUDIO_CREATE = "/onboarding/studio/create";
export const ONBOARDING_STUDIO_STYLES = "/onboarding/studio/styles";
export const ONBOARDING_STUDIO_LOCATIONS = "/onboarding/studio/locations";
export const ONBOARDING_STUDIO_WEBSITE = "/onboarding/studio/website";
export const ONBOARDING_STUDIO_DESCRIPTION = "/onboarding/studio/description";

export const ONBOARDING_REFERRALS = "/onboarding/invite";
export const ONBOARDING_SURVEY = "/onboarding/survey";
