function getThumbnailURL({ url, size, favicon }) {
  if (!url) return undefined;

  const splitByLastDot = function (text) {
    const index = text.lastIndexOf(".");
    return [text.slice(0, index), text.slice(index + 1)];
  };

  // Get photo thumbnail
  const thumbnailURL = splitByLastDot(url); // split out file extension.
  if (favicon) {
    const params = thumbnailURL[1].split("?");
    return `${thumbnailURL[0]}_favicon.png?${params[1]}`;
  }

  return `${thumbnailURL[0]}_thumb@${size || 128}.${thumbnailURL[1]}`;
}

export default getThumbnailURL;
