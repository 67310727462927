const getMessageData = async (message, jobs) => {
  const messageData = await message.data();
  const jobData = messageData.type === "job" ? jobs.find((job) => job.id == messageData.job) : null;

  return {
    ...messageData,
    job: jobData,
    id: message.id,
  };
};

export default getMessageData;
