import { firebase } from "~/src/api";

const getStudioClaim = async ({ authUser, studioID }) => {
  if (!studioID || !authUser || !authUser.admin) {
    return null;
  }

  const claims = await firebase.studioClaims(studioID).get();
  if (claims.docs.length) {
    return claims.docs[0].id;
  }

  return null;
};

export default getStudioClaim;
