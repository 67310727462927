import { firebase, getStudioData, getTeacherData } from "~/src/api";

const getJobData = async ({
  job, teacher, studio, withApplications,
}) => {
  if (!job || !job.exists) {
    return null;
  }

  const jobData = job.data();

  const result = {
    id: job.id,
    ...jobData,
  };

  if (studio || jobData.studio) {
    const jobStudio = studio || await getStudioData({ studio: await firebase.studio(jobData.studio).get() });

    result.studio = {
      ...jobStudio,
    };
  } else if (teacher || jobData.teacher) {
    const jobTeacher = teacher || await getTeacherData({ teacher: await firebase.teacher(jobData.authID).get() });

    result.teacher = {
      ...jobTeacher,
    };
  }

  if (withApplications) {
    const jobApplications = await firebase.jobApplications(job.id).get();
    if (jobApplications.exists) {
      result.applications = jobApplications.data().count;
    } else {
      result.applications = 0;
    }
  }

  return result;
};

export default getJobData;
