import { getThumbnailURL } from "~/src/api";

function getTeacherPhoto({ teacher, size, favicon }) {
  const teacherName = teacher.pro ? `${teacher.firstname} ${teacher.lastname}` : teacher.firstname;

  if (teacher.photo) {
    return getThumbnailURL({
      alt: teacherName,
      url: teacher.photo,
      size,
      favicon,
    });
  } if (teacher.photos && teacher.photos.length) {
    return getThumbnailURL({
      alt: teacherName,
      url: teacher.photos[0],
      size,
      favicon,
    });
  }

  return null;
}

export default getTeacherPhoto;
