import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import * as geofirestore from "geofirestore";

import * as ROUTES from "~/src/constants/routes";

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseUrl: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

try {
  firebase.initializeApp(config);

  /* Helper */
  firebase.fieldValue = firebase.firestore.FieldValue;
  firebase.emailAuthProvider = firebase.auth.EmailAuthProvider;

  /* Firebase APIs */
  firebase.auth = firebase.auth();
  firebase.db = firebase.firestore();
  firebase.GeoFirestore = geofirestore.initializeApp(firebase.db);
  firebase.functions = firebase.app().functions("asia-northeast1");
  firebase.storage = firebase.storage();

  // Emulate Firestore for Cypress

  if (process.env.CYPRESS) {
    console.log("Using Firestore Emulator"); // eslint-disable-line no-console
    const firestoreSettings = {
      host: "localhost:8080",
      ssl: false,
    };

    // Pass long polling setting to Firestore when running in Cypress
    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    firestoreSettings.experimentalForceLongPolling = true;

    firebase.db.settings(firestoreSettings);
  }

  // *** Auth API ***
  firebase.doCreateUserWithEmailAndPassword = (email, password) => firebase.auth.createUserWithEmailAndPassword(email, password);

  firebase.doSignInWithEmailAndPassword = (email, password) => firebase.auth.signInWithEmailAndPassword(email, password);

  firebase.doSignOut = () => firebase.auth.signOut();

  firebase.doPasswordReset = (email) => firebase.auth.sendPasswordResetEmail(email, {
    url: process.env.URL_PREFIX + ROUTES.LOG_IN,
  });

  firebase.doSendEmailVerification = () => firebase.auth.currentUser.sendEmailVerification({
    url: process.env.URL_PREFIX,
  });

  firebase.doPasswordUpdate = (password) => firebase.auth.currentUser.updatePassword(password);

  // *** User API ***
  firebase.user = (uid) => firebase.db.doc(`users/${uid}`);
  firebase.users = () => firebase.db.collection("users");

  // *** Job API ***
  firebase.job = (uid) => firebase.db.doc(`jobs/${uid}`);
  firebase.jobs = () => firebase.db.collection("jobs");
  firebase.jobApplications = (uid) => firebase.db.doc(`jobApplications/${uid}`);

  // *** Studio API ***
  firebase.studio = (uid) => firebase.db.doc(`studios/${uid}`);
  firebase.studios = () => firebase.db.collection("studios");
  firebase.studioLocations = (uid) => firebase.db.collection(`studios/${uid}/locations`);
  firebase.studioClaim = (studioID, claimID) => firebase.db.doc(`studios/${studioID}/claims/${claimID}`);
  firebase.studioClaims = (uid) => firebase.db.collection(`studios/${uid}/claims`);

  // *** Teacher API ***
  firebase.teacher = (uid) => firebase.db.doc(`teachers/${uid}`);
  firebase.teachers = () => firebase.db.collection("teachers");
  firebase.teacherQualifications = (uid) => firebase.db.collection(`teachers/${uid}/qualifications`);
  firebase.teacherExperiences = (uid) => firebase.db.collection(`teachers/${uid}/experiences`);

  // *** Sites API ***
  firebase.site = (uid) => firebase.db.doc(`sites/${uid}`);
  firebase.sites = () => firebase.db.collection("sites");

  // *** Emails API ***
  firebase.email = (uid) => firebase.db.doc(`emails/${uid}`);
  firebase.emails = () => firebase.db.collection("emails");

  // *** Subscription API ***
  firebase.subscription = (uid) => firebase.db.doc(`subscriptions/${uid}`);
  firebase.subscriptions = () => firebase.db.collection("subscriptions");

  // *** Messages API ***
  firebase.chat = (uid) => firebase.db.doc(`chats/${uid}`);
  firebase.chats = () => firebase.db.collection("chats");
  firebase.messages = (uid) => firebase.db.collection(`chats/${uid}/messages`);

  // *** Surveys API ***
  firebase.survey = (uid) => firebase.db.doc(`surveys/${uid}`);
  firebase.surveys = () => firebase.db.collection("surveys");

  // *** Referral API ***
  firebase.referral = (uid) => firebase.db.doc(`referrals/${uid}`);
  firebase.referrals = () => firebase.db.collection("referrals");

  // *** Merge Auth and DB User API *** //
  firebase.onAuthUserListener = (next, fallback) => firebase.auth.onAuthStateChanged((authUser) => {
    if (authUser) {
      firebase.users().where("authID", "==", authUser.uid)
        .get()
        .then((users) => {
          const dbUser = users.docs[0];
          const dbUserData = dbUser ? dbUser.data() : undefined;

          // merge auth and db user
          const authUserMerged = {
            authID: authUser.uid,
            email: authUser.email,
            id: dbUser && dbUser.id,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUserData,
          };

          next(authUserMerged);
        });
    } else {
      fallback();
    }
  });
} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err); // eslint-disable-line no-console
  }
}

export default firebase;
