import { firebase } from "~/src/api";

// Create a teacher profile for an existing user
const createSite = async ({
  authUser, data,
}) => {
  await firebase.site(authUser.authID).set({
    ...data,
  });

  await firebase.user(authUser.authID).update({
    site: true,
  });
};

export default createSite;
