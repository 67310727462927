import { firebase } from "~/src/api";

// Create a teacher profile for an existing user
const createTeacher = async ({
  authUser, firstname, lastname, username, photo,
}) => {
  await firebase.teacher(authUser.authID).set({
    firstname,
    lastname,
    authID: authUser.authID,
    username,
    photo,
    safetyChecks: {},
  });

  await firebase.user(authUser.authID).update({
    teacher: true,
  });
};

export default createTeacher;
