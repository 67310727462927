import { firebase } from "~/src/api";

const getJobApplications = async (jobID) => {
  const jobApplications = await firebase.jobApplications(jobID).get();

  if (jobApplications.exists) {
    return jobApplications.data();
  }
  return {
    count: 0,
  };
};

export default getJobApplications;
