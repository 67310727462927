import { firebase } from "~/src/api";

// Create a studio profile for an existing user
const createStudio = async ({
  authUser, name, photo, website, email, description, styles,
}) => {
  const data = {
    name: name || "",
    photo: photo || "",
    website: website || "",
    email: email || (authUser && authUser.email ? authUser.email : ""),
    description: description || "",
    styles: styles || [],
    registered: !!authUser,
    authID: authUser ? authUser.authID : null,
  };

  const studio = await firebase.studios().doc();
  await studio.set(data);

  // Add this studio to the user
  if (authUser && authUser.authID) {
    await firebase.user(authUser.authID).update({
      willCreateStudio: firebase.firestore.FieldValue.delete(),
      studio: studio.id,
    });
  } else {
    // Add a claim doc to this studio
    await firebase.studioClaims(studio.id).doc().set({ });
  }

  return studio;
};

export default createStudio;
