import { firebase } from "~/src/api";

// Add Job to an existing Chat
const addTeacherToJob = async ({
  job, chatRef, author, teacherID,
}) => {
  // Add job to chat
  await firebase.chat(chatRef.id).update({
    jobs: firebase.fieldValue.arrayUnion(job.id),
  });

  // Add job as message in chat
  await firebase.messages(chatRef.id).doc().set({
    type: "job",
    job: job.id,
    author,
    timestamp: firebase.fieldValue.serverTimestamp(),
  });

  // Add teacher to "applied"
  await firebase.jobApplicant(job.id, teacherID).set({
    state: "applied",
  });
};

export default addTeacherToJob;
