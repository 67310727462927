const GA_TRACKING_ID = process.env.FIREBASE_MEASUREMENT_ID; // This is your GA Tracking ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = (action, props = {}) => {
  const { category, label, value } = props;
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};

const analytics = {
  GA_TRACKING_ID,
  pageview,
  event,
};

export default analytics;
