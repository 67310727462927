import { firebase } from "~/src/api";

const getTeacherExperiences = async (teacherID) => {
  const experiences = await firebase.teacherExperiences(teacherID).get();

  const promises = experiences.docs.map(async (experience) => {
    const experienceData = experience.data();
    const studio = await firebase.studio(experienceData.studio).get();

    return ({
      id: experience.id,
      ...experienceData,
      studio: {
        id: studio.id,
        ...studio.data(),
      },
    });
  });

  return Promise.all(promises);
};

export default getTeacherExperiences;
