import { getTeacherQualifications, getTeacherExperiences } from "~/src/api";

const getTeacherData = async ({ teacher, withExperiences, withQualifications }) => {
  if (!teacher || !teacher.exists) {
    return null;
  }

  const teacherData = teacher.data();

  const result = {
    id: teacher.id,
    ...teacherData,
  };

  if (withExperiences) {
    result.experiences = await getTeacherExperiences(teacher.id);
  }
  if (withQualifications) {
    result.qualifications = await getTeacherQualifications(teacher.id);
  }

  return result;
};

export default getTeacherData;
