const getFilteredDocs = async (props) => {
  const {
    label,
    promiseFunction,
    sortFunction,
    results = [],
    lastVisible,
    filters,
    pageSize,
  } = props;

  let { query } = props;

  query = query.limit(pageSize);

  // Filters
  if (filters.discipline) {
    if (label === "jobs") {
      query = query.where("type", "==", filters.discipline);
    } else if (label === "studios") {
      query = query.where("styles", "array-contains", filters.discipline);
    } else if (label === "teachers") {
      query = query.where(`skills.${filters.discipline}`, "==", true);
    }
  }
  if (filters.safety && filters.safety.length) {
    filters.safety.forEach((safetyCheck) => {
      query = query.where(`safetyChecks.${safetyCheck}.active`, "==", true);
    });
  }
  if (filters.name) {
    if (label === "studios") {
      query = query.where("name", ">=", filters.name).where("name", "<=", `${filters.name}~`);
    }
  }
  if (filters.location) {
    if (label === "jobs" || label === "teachers") {
      query = query.where("location.state", "==", filters.location);
    }
  }

  // Pagination
  if (lastVisible) {
    query = query.startAfter(lastVisible);
  }

  const queryResult = await query.get();
  let promises = await Promise.all(queryResult.docs.map((doc) => promiseFunction(doc)));

  if (sortFunction) {
    promises = promises.sort(sortFunction);
  }

  return {
    results: results.concat(promises),
    lastVisible: queryResult.docs[queryResult.docs.length - 1],
    moreToLoad: Boolean(promises.length >= pageSize),
  };
};

export default getFilteredDocs;
