import { firebase } from "~/src/api";

// Add application to a job
const addJobApplication = async ({ teacherID, jobID }) => {
  const job = await firebase.jobApplications(jobID).get();

  if (job.exists && typeof job.data()[teacherID] !== "undefined") {
    // Then the teacher has already applied! Skip.

  } else {
    const data = {
      count: job.exists ? job.data().count + 1 : 1,
      [teacherID]: "applied",
      newApplicants: true,
    };

    await firebase.jobApplications(jobID).set(data, { merge: true });
  }
};

export default addJobApplication;
