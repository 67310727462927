/* eslint-disable no-await-in-loop */
import {
  firebase, getStudioData, getTeacherData, getJobData,
} from "~/src/api";

// Get full chat data, needed to display on a detail page
const getFullChatData = async (chat, authUser) => {
  const chatData = chat.data();

  const result = {
    ...chatData,
    id: chat.id,
    jobs: [],
  };

  for (const member of chatData.memberDetails) {
    if (member.type === "teacher") {
      if (authUser.authID === member.id) {
        // Get self data
        const selfTeacherData = await getTeacherData({ teacher: await firebase.teacher(member.id).get() });
        result.self = {
          type: "teacher",
          ...selfTeacherData,
        };
      } else {
        // Add other user as teacher
        result.teacher = await getTeacherData({ teacher: await firebase.teacher(member.id).get() });
      }
    } else if (member.type === "studio") {
      // Only get self data if have studio AND teacher
      if (authUser.studio === member.id) {
        // Get self data
        const selfStudioData = await getStudioData({ studio: await firebase.studio(member.id).get() });
        result.self = {
          type: "studio",
          ...selfStudioData,
        };
      } else {
        // Add other user as studio
        result.studio = await getStudioData({ studio: await firebase.studio(member.id).get() });
      }
    }
  }

  // Get all jobs
  if (chatData.jobs) {
    for (const job of chatData.jobs) {
      result.jobs.push(await getJobData({ job: await firebase.job(job).get() }));
    }
  }

  return result;
};

export default getFullChatData;
