import { firebase } from "~/src/api";

const getStudioPrimaryAddress = async (studioID) => {
  const primaryLocation = await firebase.studioLocations(studioID).orderBy("primary", "desc").limit(1).get();
  const primaryLocationData = await primaryLocation.docs && primaryLocation.docs.length && primaryLocation.docs[0].data();

  return primaryLocationData ? {
    id: primaryLocation.docs[0].id,
    ...primaryLocationData,
  } : null;
};

export default getStudioPrimaryAddress;
