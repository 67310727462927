import { firebase } from "~/src/api";

const getTeacherQualifications = async (teacherID) => {
  const qualifications = await firebase.teacherQualifications(teacherID).get();

  const promises = qualifications.docs.map((qualification) => {
    const qualificationData = qualification.data();

    return ({
      id: qualification.id,
      ...qualificationData,
    });
  });

  return Promise.all(promises);
};

export default getTeacherQualifications;
