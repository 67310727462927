// import { IntercomAPI } from "react-intercom";
import { identify } from "react-fullstory";
import * as Sentry from "@sentry/node";
import { firebase, getStudioData, getTeacherData } from "~/src/api";
import * as PLUGINS from "~/src/constants/plugins";

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// Create a teacher profile for an existing user
const setUserTracking = async (authUser) => {
  if (process.env.NODE_ENV === "development" || isInIframe()) return;

  // Set Sentry
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: authUser && authUser.authID,
      email: authUser && authUser.email,
    });
  });

  let studio;
  if (authUser && authUser.studio) {
    studio = await getStudioData({ studio: await firebase.studio(authUser.studio).get() });
  }
  let teacher;
  if (authUser.teacher) {
    teacher = await getTeacherData({ teacher: await firebase.teacher(authUser.authID).get() });
  }

  const name = teacher ? `${teacher.firstname} ${teacher.lastname}` : null;

  // Set FullStory
  identify(authUser.authID, {
    displayName: name,
    email: authUser.email,
  });

  const jobs = await firebase.jobs().where("authID", "==", authUser.authID).orderBy("timestamp", "asc").get();
  let firstJobPostDate = null;
  let firstJobID = null;
  if (jobs.docs && jobs.docs.length) {
    const job = jobs.docs[0];
    const jobData = job.data();

    if (jobData.open) {
      firstJobPostDate = jobData.timestamp.seconds;
      firstJobID = job.id;
    }
  }

  // Set Intercom
  const config = {
    app_id: PLUGINS.INTERCOM_ID,
    email: authUser.email,
    user_id: authUser.authID,
    name,
    firstname: teacher ? teacher.firstname : null,
    lastname: teacher ? teacher.lastname : null,
    teacher_photo: !!(teacher && teacher.photo),
    teacher_video: !!(teacher && teacher.video),
    jobs_posted: jobs.docs ? jobs.docs.length : 0,
    first_job_post_date_at: firstJobPostDate,
    first_job_id: firstJobID,
    studio_name: studio ? studio.name : null,
    studio_id: studio ? studio.id : null,
  };
  // IntercomAPI("boot", config);
};

export default setUserTracking;
