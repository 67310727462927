/* eslint-disable no-await-in-loop */
import {
  firebase, getStudioData, getTeacherData,
} from "~/src/api";

// Get minimal chat data, needed to display on a list
const getChatData = async (chat, authUser) => {
  const chatData = chat.data();

  const result = {
    ...chatData,
    id: chat.id,
  };

  for (const member of chatData.memberDetails) {
    if (member.type === "teacher" && authUser.authID !== member.id) {
      const teacher = await firebase.teacher(member.id).get();
      result.teacher = await getTeacherData({ teacher });
    } else if (member.type === "studio" && authUser.studio !== member.id) {
      const studio = await firebase.studio(member.id).get();
      result.studio = await getStudioData({ studio });
    }
  }

  // If only 1 job, get its name
  if (chatData.jobs && chatData.jobs.length === 1) {
    const job = await firebase.job(chatData.jobs[0]).get();
    const jobData = await job.data();
    chatData.jobs[0] = {
      ...jobData,
      id: job.id,
    };
  }

  // Get the first message of each of these chats.
  const recentMessage = await firebase.messages(chat.id).where("type", "==", "text").orderBy("timestamp", "desc").limit(1)
    .get();
  result.message = await recentMessage.docs && recentMessage.docs.length && recentMessage.docs[0].data();

  return result;
};

export default getChatData;
