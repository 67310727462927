import { firebase, getTeacherData } from "~/src/api";

const getSiteData = async (site) => {
  const siteData = site.data();

  const teacherID = site.id;

  const teacher = await getTeacherData({
    teacher: await firebase.teacher(teacherID).get(),
    withExperiences: true,
    withQualifications: true,
  });
  const user = await (await firebase.user(teacherID).get()).data();

  return {
    site: {
      id: site.id,
      ...siteData,
    },
    user,
    teacher,
  };
};

export default getSiteData;
