import { firebase, getStudioPrimaryAddress } from "~/src/api";

const getUserData = async (user) => {
  if (!user || !user.exists) return null;

  const userData = user.data();

  const result = {
    user: {
      id: user.id,
      ...userData,
    },
  };

  if (userData.teacher) {
    const teacher = await firebase.teacher(user.id).get();
    const teacherData = teacher.data();

    result.teacher = {
      id: teacher.id,
      ...teacherData,
    };
  }

  if (userData.studio) {
    const studio = await firebase.studio(userData.studio).get();
    const studioData = studio.data();

    result.studio = {
      id: studio.id,
      ...studioData,
      location: await getStudioPrimaryAddress(studio.id),
    };
  }

  return result;
};

export default getUserData;
