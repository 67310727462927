import uuid from "uuid";
import { firebase } from "~/src/api";

const uploadPhoto = async (props) => new Promise(((resolve, reject) => {
  const {
    photo, subdirectory, setInfo, setError,
  } = props;

  const splitByLastDot = function (text) {
    const index = text.lastIndexOf(".");
    return [text.slice(0, index), text.slice(index + 1)];
  };

  const fileExtension = splitByLastDot(photo.name)[1];
  const uploadTask = firebase.storage.ref(`images/${subdirectory || "teachers"}/${uuid.v1()}.${fileExtension}`).put(photo);

  uploadTask.on("state_changed", () => {
    // Observe state change events such as progress, pause, and resume
    setInfo("Uploading new photo(s)...");
  }, (error) => {
    // Handle unsuccessful uploads
    setInfo(null);
    setError(error);
    reject(error);
  }, () => {
    setInfo(null);


    // Handle successful uploads on complete
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      resolve(downloadURL);
    });
  });
}));

export default uploadPhoto;
