import { firebase } from "~/src/api";

const RADIUS = [0, 1, 5, 20, 50];

const constructQuery = (props) => {
  const {
    label, filters, radiusIndex, weeksOld,
  } = props;
  let { query } = props;

  // Filters
  query.near({
    center: new firebase.firestore.GeoPoint(filters.latitude, filters.longitude),
    radius: RADIUS[radiusIndex],
  });

  if (label === "jobs") {
    if (weeksOld === 1) {
      query = query.where("inLastWeek", "==", true);
    } else {
      query = query.where("inLastWeek", "==", false).where("inLastFortnight", "==", true);
    }
  }

  if (filters.discipline) {
    if (label === "jobs") {
      query = query.where("type", "==", filters.discipline);
    } else if (label === "teachers") {
      query = query.where(`skills.${filters.discipline}`, "==", true);
    }
  }
  if (filters.safety && filters.safety.length) {
    filters.safety.forEach((safetyCheck) => {
      query = query.where(`safetyChecks.${safetyCheck}.active`, "==", true);
    });
  }

  return query;
};

const getFilteredGeoDocs = async (props) => {
  const {
    label,
    promiseFunction,
    sortFunction,
    results = [],
    filters,
    pageSize,
    weeksOld,
  } = props;

  let { query, radiusIndex } = props;
  let queryResult = {
    docs: [],
  };

  while (queryResult.docs.length < pageSize && radiusIndex + 1 < RADIUS.length) {
    if (filters.latitude && filters.longitude) {
      radiusIndex += 1;
    }
    query = constructQuery({
      query, label, filters, radiusIndex, weeksOld,
    });
    queryResult = await query.get();
  }

  let promises = await Promise.all(queryResult.docs.map((doc) => promiseFunction(doc)));

  if (sortFunction) {
    promises = promises.sort(sortFunction);
  }

  return {
    results: results.concat(promises),
    radiusIndex,
    moreToLoad: false,
  };
};

export default getFilteredGeoDocs;
