import { firebase } from "~/src/api";
import EMAIL_SETTINGS_INITIAL_VALUES from "~/src/constants/initialValues/emailSettings";

// Create a studio profile for an existing user
const createUser = async ({
  email, password, onResolve, onReject,
}) => {
  try {
    const authUser = await firebase.doCreateUserWithEmailAndPassword(email, password);
    await firebase.user(authUser.user.uid).set({
      email,
      authID: authUser.user.uid,
      emailSettings: EMAIL_SETTINGS_INITIAL_VALUES,
    });
    await firebase.doSendEmailVerification();
    onResolve();
  } catch (error) {
    onReject(error);
  }
};

export default createUser;
