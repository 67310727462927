import { firebase, getJobData } from "~/src/api";

const verifyStudioClaimID = async ({ studioID, claimID }) => {
  if (!studioID || !claimID) {
    return null;
  }

  const claim = await firebase.studioClaim(studioID, claimID).get();

  if (claim.exists) {
    return claimID;
  }

  return false;
};

const getJobToBeClaimed = async ({ jobID, claimID }) => {
  const job = await getJobData({
    job: await firebase.job(jobID).get(),
  });

  if (!job || !job.studio) return null;

  const claim = await verifyStudioClaimID({
    studioID: job.studio.id,
    claimID,
  });

  return {
    job,
    claim,
  };
};

export default getJobToBeClaimed;
