import { firebase } from "~/src/api";

// Create a message between another user and the self
const createChatActual = async (member1, member2) => {
  const chatRef = firebase.chats().doc();

  await chatRef.set({
    members: [
      member1.id,
      member2.id,
    ],
    memberDetails: [
      member1,
      member2,
    ],
    membersConcat: `${member1.id}_${member2.id}`,
  });

  return chatRef;
};

// Abstracted to simplify creating a chat
// 1. We know we don't have a chat
// 2. We know the self -> either selfTeacher or selfTeacher
// 3. And we have a list of targets -> teacher, studio or job.
const createChat = async (props) => {
  const {
    self, teacher, studio, job,
  } = props;

  if (teacher) {
    return createChatActual(self, {
      type: "teacher",
      id: teacher.authID,
    });
  } if (studio) {
    return createChatActual(self, {
      type: "studio",
      id: studio.id,
    });
  } if (job) {
    // If job, then gotta work out whether job owned by studio or not.
    if (job.studio) {
      return createChatActual(self, {
        type: "studio",
        id: job.studio.id,
      });
    }
    return createChatActual(self, {
      type: "teacher",
      id: job.authID,
    });
  }
};

export default createChat;
