import { firebase, getJobData, getStudioPrimaryAddress } from "~/src/api";

const getStudioData = async ({ studio, withLocations, withJobs }) => {
  if (!studio || !studio.exists) {
    return null;
  }

  const studioData = studio.data();

  const result = {
    id: studio.id,
    ...studioData,
  };

  result.location = await getStudioPrimaryAddress(studio.id);

  if (withLocations) {
    const locations = await firebase.studioLocations(studio.id).orderBy("primary", "desc").get();
    const locationPromises = locations.docs.map((location) => ({
      id: location.id,
      ...location.data(),
    }));
    result.locations = await Promise.all(locationPromises);
  }

  if (withJobs) {
    const jobs = await firebase.jobs().where("studio", "==", studio.id).get();
    const jobPromises = jobs.docs.map((job) => getJobData({ job, studio: result }));
    result.jobs = await Promise.all(jobPromises);
  }

  return result;
};

export default getStudioData;
