/* eslint-disable no-await-in-loop */
import { firebase } from "~/src/api";

// Get all chats between the two users
const getChatsForDetailPages = async (props) => {
  const { authUser, otherID } = props;

  const concats = [];
  const arrayCheck = [];
  if (authUser.teacher) {
    // Check for chat between authUser's teacher and this studio
    concats.push(`${authUser.authID}_${otherID}`);
    concats.push(`${otherID}_${authUser.authID}`);
    arrayCheck.push(authUser.authID);
  }

  if (authUser.studio) {
    // Check for chat between authUser's studio and this studio
    concats.push(`${authUser.studio}_${otherID}`);
    concats.push(`${otherID}_${authUser.studio}`);
    arrayCheck.push(authUser.studio);
  }

  if (!arrayCheck.length) return null;

  // Get all chats that match (up to 2)
  const chats = await firebase.chats()
    .where("members", "array-contains-any", arrayCheck)
    .get();

  // Get all chat data
  const chatsResult = [];

  for (const chat of chats.docs) {
    const chatData = await chat.data();

    // Only include if the chat is relevant to the current page.
    if (concats.includes(chatData.membersConcat)) {
      chatsResult.push({
        id: chat.id,
        ...chatData,
      });
    }
  }

  return chatsResult;
};

export default getChatsForDetailPages;
