import { firebase } from "~/src/api";

// Get teachers, with optional filters.
const getTeachers = async ({ location, discipline, safetyChecks }) => {
  let query = firebase.teachers();

  if (discipline) {
    query = query.where(`skills.${discipline}`, "==", true);
  }
  if (safetyChecks) {
    safetyChecks.forEach((safetyCheck) => {
      query = query.where(`safetyChecks.${safetyCheck}.active`, "==", true);
    });
  }
  if (location) {
    query = query.where("location.state", "==", location);
  }

  return query.get();
};

export default getTeachers;
